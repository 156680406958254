<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/settings`"
                title="Settings"
                :columns="columns"
                routerpath="/setup/addsettings"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search name'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Settings' : 'Add Settings'
        }`,
        submitRouterPath: '/setup/settings',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        gridForm: true,
        url: `${baseApi}/settings`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Settings Name',
            class: 'col-md-12',
            required: true
          }
        ]
      }
    }
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
